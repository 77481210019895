import PS from '../PromostandardsLabel';

export const geometry_options = [
    { value: 'CIRCLE', label: <span><PS />Circle</span> },
    { value: 'RECTANGLE', label: <span><PS />Rectangle</span> },
    { value: 'OTHER', label: <span><PS />Other</span> }
];

export const uom_options = [
    { value: 'Inches', label: <span><PS />Inches</span> },
    { value: 'cm', label: <span><PS />cm</span> }
];