import { useCallback, useEffect, useState } from 'react';
import { oauth } from '../../../utils';

export default function useArtworkDimension(initData, artwork_dimension_id, getCallback, saveCallback) {
    const [artworkDimensionId, setArtworkDimensionId] = useState(artwork_dimension_id);
    const saveDimension = useCallback(async (data) => {
        let dimensionData = null;
        if (!artworkDimensionId) {
            dimensionData = await oauth('POST', 'artwork-dimension', data);
        } else {
            if (!data.artwork_dimension_id) return;
            dimensionData = await oauth('PUT', 'artwork-dimension', data);
        }

        setArtworkDimensionId(dimensionData.json.artwork_dimension.artwork_dimension_id);
        saveCallback(dimensionData.json.artwork_dimension);

    }, [artworkDimensionId, saveCallback]);

    useEffect(() => {
        let ignore = false;
        const getDimension = async () => {
            if (!artwork_dimension_id) {
                return saveDimension(initData);
            };

            const dimensionData = await oauth('GET', 'artwork-dimension', {
                id: artwork_dimension_id,
            });

            if (!ignore) getCallback(dimensionData.json.artwork_dimension);
        };

        getDimension();

        return () => {
            ignore = true;
        };

    }, [artwork_dimension_id]);

    return [
        saveDimension,
    ];
};
