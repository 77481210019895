import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { colors } from '@commonsku/styles';
import { getProductConfigurations } from '../promostandards';
import { getConfigurationDecoration, hasArtworkDimension } from '../promostandard_utils';
import { getImprintDropdown, getProofOptions } from '../selectors/dropdowns';
import { closePopup, createArtworkLibraryPopup, createSelectFilePopup } from '../actions/popup';
import { createUpdateArtwork, createAddArtwork, createDeleteArtwork } from '../actions/artwork';
import { createFetchProduct } from '../actions/product';
import { createDeleteTemp } from '../actions/temp';
import { getImageSrc } from '../utils';
import { TBD_FILE_ID } from './ArtworkLibrary';

import Form from './Form';
import Img from './Img';
import PS from './PromostandardsLabel';
import Loading from './Loading';

import { BASE_ZINDEX } from '../popup-factory';
import ArtworkFormDimension from './artwork/form/Dimension';
import { withRouter } from './helpers';

const TEMP_FILE_REGISTER = 'artwork_file_id';

const StyledForm = styled(Form)`
  &&&&& {
    .has-error .field-control {
      border: 1px solid ${colors.errors['60']};
      border-radius:  5px;
    }
    .field-error {
      font-size: small;
      font-weight: 600;
      margin: -12px 0 10px;
      text-align: start;
      color: ${colors.errors['60']};
    }
  }
`;

const LogoColorField = styled(({ className, ...props }) => {
  return <Form.Textarea {...props} className={className}/>;
})`
  &&&.has-error {
    textarea {
      border: 3px solid ${colors.errors['50']};
      animation: logoColorBorderChange 300ms forwards linear;
    }
  }
  
  @keyframes logoColorBorderChange {
    0%, 66% { border: 3px solid ${colors.errors['50']}; }
    100% { border: 1px solid ${colors.errors['60']}; }
  }
`;

class EditArtworkPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      artwork: props.artwork,
    };

    this.artworkDimensionRef = React.createRef();

    _.bindAll(this, ['onFormRef', 'handleUpdateArtwork', 'handleFieldChange']);
  }

  componentDidMount() {
    if ('ps-products' === this.props.item.copied_from) {
      this.loadPSProductConfigurations();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { artwork_file_id, image, imprint_id } = nextProps.artwork;
    this.setState({
      artwork: Object.assign({}, this.state.artwork, {
        artwork_file_id, image,
        imprint_id: this.state.artwork.imprint_id || imprint_id
      })
    });
  }

  componentDidUpdate(prevProps) {
    if ('ps-products' === this.props.item.copied_from && !prevProps.product && this.props.product) {
      this.loadPSProductConfigurations();
    }
  }

  async loadPSProductConfigurations() {
    const { product, item_location, item_decoration, item, getProduct } = this.props;
    if (!product) {
      getProduct(item.parent_id);
      return;
    }

    const ps_location_id = _.get(item_location, 'ext_location_id');
    const ps_decoration_id = _.get(item_decoration, 'ext_decoration_id');
    let configuration;
    try {
      configuration = await getProductConfigurations({
        productId: product.ext_product_id,
      }) || false;
    } catch (e) {
      configuration = false;
    }

    const decoration = configuration ? getConfigurationDecoration(configuration, ps_location_id, ps_decoration_id) : null;

    if (!decoration) {
      this.setState({ getDecorationFailed: true });
      return;
    } else {
      this.setState({ getDecorationFailed: false });
    }

    this.setState({ decoration });
  }

  componentWillUnmount() {
    this.props.onCleanUp();
  }

  onFormRef(form) {
    this._form = form;
  }

  handleUpdateArtwork(artwork, errors) {
    if (_.every(errors, (error) => error === null)) {
      this.props.onUpdateArtwork(artwork, this.props.copy);
    }
  }

  handleFieldChange(value, field) {
    this.setState({
      artwork: {
        ...this.state.artwork,
        [field]: value,
      },
    });
  }

  renderLoading() {
    const { index, copy, locked } = this.props;
    const { artwork } = this.state;
    return (
      <div id="add-art-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">{artwork.artwork_id && !copy ? (locked ? 'Artwork' : 'Update Artwork') : 'Add Artwork'}</h3>
          </div>
        </div>
        <div className="row popup-content">
          <div className="small-12 columns">
            <Loading />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      locked, copy, imprints, proofs, account_id, is_project, job_id, quirks, item,
      onClosePopup, onDeleteArtwork, onOpenArtworkLibrary, onCreateSelectFilePopup, index, product,
    } = this.props;

    const { artwork, decoration, getDecorationFailed } = this.state;
    const img_src = getImageSrc(artwork.image, 'medium');
    const canEditImprint = !locked && !artwork.item_decoration_id;
    const is3rdPartyDecorator = !!JSON.parse(item.is_3rd_party_decorator);

    const handleCancel = e => {
      e.preventDefault();
      onClosePopup();
    };

    const handleDelete = e => {
      e.preventDefault();
      onDeleteArtwork(artwork.artwork_id);
    };

    const showArtworkDimension = getDecorationFailed === false && hasArtworkDimension(quirks, item.division_id) && 'ps-products' === item.copied_from && !is3rdPartyDecorator;
    const showPSColorFieldError =  getDecorationFailed === false && 'ps-products' === item.copied_from && !is3rdPartyDecorator;

    const handleSave = e => {
      e.preventDefault();
      // Make sure we have artwork dimension id
      if (showArtworkDimension) {
        if (!this.artworkDimensionRef.current?.isValid() || !artwork.artwork_dimension_id) {
          return;
        }
      }
      this._form && this._form.submit();
    };

    const save_style = { position: 'fixed', right: '1rem', top: '1rem' };
    const delete_style = { position: 'fixed', right: '5rem', top: '1rem' };
    const cancel_style = locked ? { position: 'fixed', right: '1rem', top: '1rem' } :
      (artwork.artwork_id && canEditImprint && !copy ? { position: 'fixed', right: '9.8rem', top: '1rem' } :
      { position: 'fixed', right: '5rem', top: '1rem' });

    return (
      <div id="add-art-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">{artwork.artwork_id && !copy ? (locked ? 'Artwork' : 'Update Artwork') : 'Add Artwork'}</h3>
          </div>
        </div>
        <StyledForm
          className="row popup-content form" object={artwork} ref={this.onFormRef}
          errorPosition="bottom" errorClassName="small-12 medium-8 columns"
          onSubmit={this.handleUpdateArtwork} onFieldChange={this.handleFieldChange}
        >
          <div className="small-12 medium-6 columns">
            <Form.Select label="Imprint Type" field="imprint_id"
              required={true} options={imprints} disabled={!canEditImprint}
              withMarginBottom
            />

            <Form.Select label="Proof Required" field="proof_id"
              required={true}  options={proofs} disabled={locked}
              withMarginBottom
            />
            <Form.Textarea label="Logo Name" field="artwork_title" required={true} disabled={locked}/>
            {showArtworkDimension ? (
              !!decoration ? <ArtworkFormDimension
              ref={this.artworkDimensionRef}
              id={artwork.artwork_dimension_id}
              decoration={decoration}
              locked
              validateFormCallback={(data, valid) => {
                if (!valid) return;
                this.setState((state) => {
                  return {
                    ...state,
                    'artwork': {
                      ...state['artwork'],
                      artwork_dimension_id: data.artwork_dimension_id
                    }
                  };
                });
              }}
            /> : <Loading />) :
            <><Form.TextInput label="Logo Size" field="artwork_size" required={true} disabled={locked}/>
              {!!decoration &&
                <div className="row">
                  <div className="small-12 medium-8 medium-push-4 columns" style={{ marginTop: '-0.5rem', marginBottom: '1rem', fontSize: 'small' }}>
                    {decoration.geometry}
                  </div>
                </div>}
            </>
            }

            <LogoColorField
              label="Logo Color" field="artwork_color"  required={true} disabled={locked}
              onChange={(value) => {
                if (showPSColorFieldError) {
                  setTimeout(() => {
                    this._form.validateField('artwork_color', value);
                  });
                }
              }}
              validate={(value, _props, error) => {
                // PS spec limit is 64, but HIT require limit to be 16.
                const limit = item.division_id === 'a0068b9b-1980-4dcb-93d4-9deba3e72f18' ? 16 : 64;
                return error || (!(showPSColorFieldError && _.size(value) > limit) ? null : {
                  message: `Max ${limit} characters. Use Supplier Notes for longer text.`
                });
              }}
            />

            <Form.Select label="Repeat Logo?" field="artwork_repeat" disabled={locked}
              withMarginBottom
              options={[
                { key: 0, value: 'No' },
                { key: 1, value: 'Yes' }
              ]}/>
            <Form.Textarea label="Supplier Notes" field="artwork_notes" disabled={locked} />
          </div>
          <div className="small-12 medium-6 columns">
            {!!account_id ?
              <Form.BaseField field="artwork_file_id"
                Control="div" displayLabel={false}
                validate={() => {
                  return artwork.image || artwork.artwork_file_id === TBD_FILE_ID ? null : {message: 'Image is required'};
                }}
                onClick={e => {
                  if (!locked) {
                    if (is_project) {
                      onCreateSelectFilePopup(TEMP_FILE_REGISTER, job_id, 'JOB', account_id);
                    } else {
                      onOpenArtworkLibrary(TEMP_FILE_REGISTER);
                    }
                  }
                  this._form && this._form.cleanFieldError('artwork_file_id');
                }}
              >
                <div className="art-placeholder" style={{marginBottom: '1em'}}>
                  {artwork.image ? <Img src={img_src} /> :
                    (TBD_FILE_ID === artwork.artwork_file_id ?
                    <img src="/images/404.png" /> :
                    (locked ? 'No Image' : 'Select Image'))}
                </div>
              </Form.BaseField> :
            <div className="art-placeholder" style={{ marginBottom: '1em' }}>
              Client will select image
            </div>}
          </div>
        </StyledForm>
        <a className="alert button" style={cancel_style} onClick={handleCancel}>{locked ? 'Done' : 'Cancel'}</a>
        {!locked && !copy && canEditImprint && artwork.artwork_id ? <a className="warning button" style={delete_style} onClick={handleDelete}>Delete</a> : null}
        {!locked ? <a className="button" style={save_style} onClick={handleSave}>Save</a> : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const base_imprints = getImprintDropdown(state).sort(
    (a, b) => {
      if (!!a.ext_artwork_id && !b.ext_artwork_id) {
        return -1;
      } else if (!!b.ext_artwork_id && !a.ext_artwork_id) {
        return 1;
      }
      if (a.imprint_name < b.imprint_name) {
        return -1;
      } else if (b.imprint_name < a.imprint_name) {
        return 1;
      }
      return 0;
    }
  );
  const artwork_file_id = state.temp[TEMP_FILE_REGISTER];
  const image = state.entities.files[artwork_file_id];
  const image_update = undefined !== artwork_file_id ? { artwork_file_id, image } : {};

  const proofs = [{
    key: '', value: 'Please select a proof type...',
  }].concat(getProofOptions(state));

  const default_artwork = {
    proof_id: proofs[0].key,
    artwork_title: '',
    artwork_size: '',
    artwork_color: '',
    artwork_repeat: 0,
    artwork_notes: '',
    artwork_dimension_id: ''
  };

  const artwork = Object.assign({}, default_artwork, ownProps.artwork, image_update);
  const locked = ownProps.locked;
  const item = state.entities.items[artwork.item_id];
  const product = _.get(state.entities.products, item.parent_id);
  const item_location = _.get(state.entities.item_locations, artwork.item_location_id);
  const item_decoration = _.get(state.entities.item_decorations, artwork.item_decoration_id);
  const imprint = _.get(state.entities.imprints, _.get(item_decoration, 'imprint_id')) || {};

  const imprints = [{
    key: '',
    value: 'Please select an imprint type...'
  }].concat(
    _.get(imprint, 'tenant_id') === 'PROMOSTANDARDS' ?
    [{
      key: imprint.imprint_id,
      value: <span><PS />{imprint.imprint_name}</span>
    }] :
    base_imprints.filter(i => !i.ext_artwork_id).map(i => ({
      key: i.imprint_id,
      value: i.imprint_name
    }))
  );

  let job_id = null;
  const is_project = /\/project\//.test(ownProps.router.location.pathname);
  if(is_project) {
    job_id = Object.keys(state.entities.projects)[0];
  }

  return {
    imprints,
    proofs,
    artwork: { ...artwork, imprint_id: artwork.imprint_id || imprints[0].key || imprint.imprint_id, },
    locked,
    item,
    product,
    item_location,
    item_decoration,
    is_project,
    job_id,
    quirks: state.entities.promostandards_quirks,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getProduct: (productId) => {
      dispatch(createFetchProduct(productId));
    },
    onCleanUp: () => {
      dispatch(createDeleteTemp(TEMP_FILE_REGISTER));
    },
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onUpdateArtwork: (artwork, copy) => {
      if (artwork.artwork_id && !copy) {
        dispatch(createUpdateArtwork(artwork));
      } else {
        dispatch(createAddArtwork(artwork));
      }
      dispatch(closePopup());
    },
    onDeleteArtwork: artwork => {
      dispatch(createDeleteArtwork(artwork));
      dispatch(closePopup());
    },
    onOpenArtworkLibrary: file_register => {
      dispatch(createArtworkLibraryPopup(file_register, null, ownProps.account_id, ownProps.account_type));
    },
    onCreateSelectFilePopup: (register, parent_id, parent_type, client_id) => {
      dispatch(createSelectFilePopup(register, parent_id, parent_type, client_id));
    }
  };
};

const ConnectedEditArtworkPopup = withRouter(connect(mapStateToProps, mapDispatchToProps)(EditArtworkPopup));
export default ConnectedEditArtworkPopup;
