import React, { useCallback, useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import { Row, Col, Toggle, ToggleLink, LabeledSelect, LabeledIconInput } from '@commonsku/styles';
import { geometry_options, uom_options } from '../artwork_options';
import useArtworkDimension from '../hooks/useArtworkDimension';
import PS from '../../PromostandardsLabel';

const ArtworkFormDimension = ({
    id, validateFormCallback, locked, decoration
}, ref) => {
    const defaultValues = useMemo(() => ({
        artwork_dimension_id: '',
        dimension_geometry: decoration.geometry,
        dimension_use_max: 0,
        dimension_width: '',
        dimension_height: '',
        dimension_diameter: '',
        dimension_uom: decoration.uom,
    }), [decoration.geometry, decoration.uom]);

    const maxDiameter = parseFloat(decoration.decoration_diameter).toFixed(4);
    const maxWidth = parseFloat(decoration.decoration_width).toFixed(4);
    const maxHeight = parseFloat(decoration.decoration_height).toFixed(4);
    const maxSizeLabel = decoration.geometry.toLowerCase() === 'circle' ? maxDiameter : `${maxWidth}x${maxHeight}`;

    const [form, setForm] = useState(defaultValues);
    const [formErrors, setFormErrors] = useState(defaultValues);
    const [saveDimension] = useArtworkDimension(
        defaultValues,
        id,
        (data) => { setForm(data); validateFormCallback(data, validate(data, false)); },
        (data) => { setForm(data); validateFormCallback(data, validate(data, false)); }
    );

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => {
            const newValues = { ...form, [target.name]: target.value };
            return newValues;
        });
    }, []);

    const validateNumberFormElement = useCallback((values, element, maxValue, setError) => {
        const value = values[element];
        if (value === '') return true;

        if (isNaN(parseFloat(value)) || parseFloat(value) < 0) {
            if (setError) {
                setFormErrors((errors) => ({ ...errors, [element]: 'Invalid value' }));
            }
            return false;
        }
        if (setError) {
            setFormErrors((errors) => ({ ...errors, [element]: '' }));
        }
        return true;
    }, []);

    const validate = useCallback((values, setError) => {
        let valid = true;
        // Validate
        if (values.dimension_geometry.toLowerCase() === 'circle') {
            if (values.dimension_use_max == 0) {
                if (!validateNumberFormElement(values, 'dimension_diameter', maxDiameter, setError)) {
                    valid = false;
                }
            }
        } else {
            if (values.dimension_use_max == 0) {
                if (!validateNumberFormElement(values, 'dimension_width', maxWidth, setError)) {
                    valid = false;
                }
                if (!validateNumberFormElement(values, 'dimension_height', maxHeight, setError)) {
                    valid = false;
                }
            }
        }
        return valid;
    }, [maxDiameter, maxHeight, maxWidth, validateNumberFormElement]);

    const validateAndSave = useCallback((values) => {
        const valid = validate(values, true);
        if (!valid) {
            return false;
        }
        saveDimension(values);
    }, [saveDimension, validate]);

    useImperativeHandle(ref, () => {
        return {
            isValid() {
                return validate(form, true);
            }
        };
    }, [form, validate]);

    const getSelect = (name, options) => {
        let value = form[name];
        let selected = null;
        if (value) {
            selected = options.find(c => c.value === value);
            // Option not in the list, add it on the fly
            if (!selected) {
                selected = {
                    value: value,
                    label: <span><PS />{value}</span>
                };
                options.push(selected);
            }
        } else {
            value = null;
        }
        return (
            <LabeledSelect
                isDisabled={locked}
                name={name}
                value={selected}
                options={options}
                onChange={(selected) => {
                    setFormFieldValue({ target: { name: name, value: selected.value } });
                    validateAndSave({ ...form, [name]: selected.value });
                }}
            />
        );
    };

    const getInput = (name, inlineLabel = '', note = '') => {
        const hasError = formErrors[name] !== '';
        const value = String(form[name]) === '0.0000' ? '' : form[name];
        return (
            <div style={{ padding: '0 0.25em' }}>
                <LabeledIconInput name={name} value={value} onChange={setFormFieldValue} onBlur={() => { validateAndSave(form); }} Icon={<div>{inlineLabel}</div>} />
                {note ? <div>{note}</div> : null}
                {hasError ? <span style={{ color: 'red' }}>{formErrors[name]}</span> : ''}
            </div>
        );
    };

    const measures = form.dimension_geometry.toLowerCase() === 'circle' ?
        <Col sm={12}>
            <Row>
                <Col sm={12} md={4}>
                    <label style={{ fontSize: 'initial' }}></label>
                </Col>
                <Col sm={12} md={8} style={{ paddingRight: '0.75em' }}>
                    {getInput('dimension_diameter', 'D', `Maximum ${maxDiameter}`)}
                </Col>
            </Row>

        </Col> :
        <Col sm={12}>
            <Row>
                <Col sm={12} md={4}>
                    <label style={{ fontSize: 'initial' }}></label>
                </Col>
                <Col sm={12} md={4}>
                    {getInput('dimension_width', 'W', `Maximum ${maxWidth}`)}
                </Col>
                <Col sm={12} md={4} style={{ paddingRight: '0.75em' }}>
                    {getInput('dimension_height', 'H', `Maximum ${maxHeight}`)}
                </Col>
            </Row>

        </Col>
        ;

    let formFields = (
        <Row style={{ border: '2px solid #00A0B6', borderRadius: '10px', marginBottom: '0.75em' }}>
            <Col sm={12} style={{ background: '#00A0B6', padding: '0.5em 1em', borderRadius: '8px 8px 0 0', }}>
                <Row>
                    <Col sm={12} md={4}>
                        <label style={{ fontSize: 'initial', color: 'white' }}>Geometry</label>
                    </Col>
                    <Col sm={12} md={8}>
                        {getSelect('dimension_geometry', geometry_options)}
                    </Col>
                </Row>
            </Col>
            <Col sm={12}>
                <Row style={{ margin: '0.75em 0' }}>
                    <Col sm={12} style={{ padding: '0.5em 1em' }}>
                        <Row>
                            <Col sm={12} md={4}>
                                <label style={{ fontSize: 'initial' }}>Size</label>
                            </Col>
                            <Col sm={12} md={8}>
                                <Toggle>
                                    <ToggleLink id="0" selected={form.dimension_use_max == 0} onClick={e => {
                                        e.preventDefault();
                                        setFormFieldValue({ target: { name: 'dimension_use_max', value: 0 } });
                                        validateAndSave({ ...form, dimension_use_max: 0 });
                                    }}>Choose Size</ToggleLink>
                                    <ToggleLink id="1" selected={form.dimension_use_max == 1} onClick={e => {
                                        e.preventDefault();
                                        setFormFieldValue({ target: { name: 'dimension_use_max', value: 1 } });
                                        validateAndSave({ ...form, dimension_use_max: 1 });
                                    }}>Set to Maximum Size
                                    </ToggleLink>
                                </Toggle>
                            </Col>
                        </Row>

                    </Col>
                    {form.dimension_use_max == 1 ? null : measures}
                </Row>
                {form.dimension_use_max == 1 ? null : <Row>
                    <Col sm={12} style={{ padding: '0.5em 1em' }}>
                        <Row>
                            <Col sm={12} md={4}>
                                <label style={{ fontSize: 'initial' }}>Unit</label>
                            </Col>
                            <Col sm={12} md={8}>
                                {getSelect('dimension_uom', uom_options, 'Units')}
                            </Col>
                        </Row>
                    </Col>
                </Row>}

            </Col>
        </Row>
    );

    return formFields;
};

export default forwardRef(ArtworkFormDimension);
